import * as React from 'react';

export const InPerson = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.43 8.72c0 .22-.18.41-.41.41h-.99v3.52c0 .71-.57 1.28-1.28 1.28h-2.1l2.04 4.98c.08.21-.01.45-.22.53-.05.02-.1.03-.15.03-.16 0-.31-.1-.38-.25l-2.16-5.29H9.24l-2.16 5.29c-.06.16-.22.25-.38.25-.05 0-.1 0-.15-.03a.397.397 0 01-.22-.53l2.04-4.98H6.26c-.71 0-1.28-.57-1.28-1.28V9.13h-.99c-.22 0-.41-.18-.41-.41 0-.23.18-.41.41-.41h1.29c.28 0 .52.23.52.52v3.82c0 .26.21.47.47.47h11.51c.26 0 .47-.21.47-.47V8.83c0-.28.23-.52.52-.52h1.29c.22 0 .41.18.41.41h-.04zM7.29 11.47c.22 0 .41-.18.41-.41V5.55c0-.08.06-.14.14-.14h8.33c.08 0 .14.06.14.14v5.51c0 .22.18.41.41.41.23 0 .41-.18.41-.41V5.55a.95.95 0 00-.95-.95H7.84a.95.95 0 00-.95.95v5.51c0 .22.18.41.41.41h-.01z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
