import * as React from 'react';

export const Audience = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.306 11.649a3.7 3.7 0 00.541-1.93A3.723 3.723 0 0014.128 6a3.723 3.723 0 00-3.72 3.72c0 .705.199 1.365.542 1.929a7.109 7.109 0 00-3.879 5.967.682.682 0 00.684.716H20.5a.687.687 0 00.684-.716 7.108 7.108 0 00-3.879-5.967h.001zm-3.178-4.902a2.977 2.977 0 012.973 2.973 2.977 2.977 0 01-2.973 2.974 2.977 2.977 0 01-2.973-2.974 2.977 2.977 0 012.973-2.973zm-6.309 10.84a6.357 6.357 0 013.59-5.333 3.712 3.712 0 005.438 0 6.357 6.357 0 013.59 5.332H7.819zm-.47-5.333a6.312 6.312 0 00-3.602 5.706.374.374 0 01-.747 0 7.055 7.055 0 013.89-6.312 3.717 3.717 0 01-.544-1.928 3.723 3.723 0 013.719-3.719.374.374 0 010 .747 2.977 2.977 0 00-2.973 2.974c0 1.042.558 2.022 1.455 2.557a.372.372 0 11-.382.64 3.656 3.656 0 01-.818-.664l.001-.001z"
      fill="#212326"
    />
  </svg>
);
