import * as React from 'react';

export const Specialties = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.45 18.8c-.1 0-.2-.05-.3-.1l-4.1-3-4.1 3c-.2.15-.45.15-.6 0-.2-.15-.25-.35-.2-.6l1.55-4.85-4.1-3c-.2-.15-.25-.35-.2-.6.05-.2.25-.35.5-.35H10l1.5-4.8c.05-.2.25-.35.5-.35s.45.15.5.35l1.55 4.85h5.1c.25 0 .45.15.5.35.05.2 0 .45-.2.6l-4.1 3 1.55 4.85c.05.2 0 .45-.2.6-.05 0-.15.05-.25.05zm-10.7-8.65l3.85 2.8-1.5 4.55 3.85-2.8 3.85 2.8-1.5-4.55 3.85-2.8H13.5L12 5.6l-1.5 4.55H5.75z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
