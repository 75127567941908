import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserRead } from '@headway/api/models/UserRead';
import { UserApi } from '@headway/api/resources/UserApi';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@headway/shared/react-query';

export const getUseUserAppointmentReadinessQueryKey = (
  userId: number
): QueryKey => ['UserAppointmentReadiness', userId];

export const useAppointmentReadiness = (
  user: UserRead,
  providerId?: number,
  options?: UseQueryOptions<UserAppointmentReadiness>
) =>
  useQuery(
    getUseUserAppointmentReadinessQueryKey(user.id),
    () => {
      return UserApi.getUserAppointmentReadiness(user.id, {
        provider_id: providerId,
      });
    },
    {
      staleTime: Infinity,
      ...options,
    }
  );
