import styled from '@emotion/styled';

import { theme } from '@headway/ui/theme';

export const NavTitle = styled.h3({
  fontSize: theme.fontSize.xl,
  fontFamily: theme.fontFamily.postGrotesk,
  fontWeight: theme.fontWeight.bold,
  marginTop: 0,
  marginBottom: theme.space.base,
  color: theme.color.black,
  [theme.media.small]: {
    fontSize: theme.fontSize.base,
  },
});
