import * as React from 'react';

export const LifeExperience = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.96 13.72V9.17c0-.62-.36-1.16-.94-1.4a1.49 1.49 0 00-1.65.33l-7.62 7.62c-.21.21-.5.26-.76.15a.675.675 0 01-.43-.65v-4.55c.91-.19 1.59-1 1.59-1.96 0-1.1-.9-2-2-2s-2 .9-2 2c0 .96.69 1.77 1.59 1.96v4.55c0 .62.36 1.16.94 1.4.19.08.39.12.59.12.39 0 .77-.15 1.06-.45l7.62-7.62c.21-.21.5-.26.76-.15.27.11.43.35.43.65v4.55c-.91.19-1.59 1-1.59 1.96 0 1.1.9 2 2 2s2-.9 2-2c0-.96-.69-1.77-1.59-1.96zm-13-5c0-.65.53-1.19 1.19-1.19.66 0 1.19.53 1.19 1.19 0 .66-.53 1.19-1.19 1.19-.66 0-1.19-.53-1.19-1.19zm12.59 8.15c-.65 0-1.19-.53-1.19-1.19 0-.66.53-1.19 1.19-1.19.66 0 1.19.53 1.19 1.19 0 .66-.53 1.19-1.19 1.19z"
      fill="#353535"
    />
  </svg>
);
