import { AlertColor, useMediaQuery } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/ui/theme';

import { IAuthStore } from '../../stores/withStores';
import { RevealableTextField } from './RevealableTextField';

export const loginFormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email.').required('Email is required.'),
  password: Yup.string().required('Password is required.'),
});

export type LoginFormSchemaType = Yup.InferType<typeof loginFormSchema>;

interface LoginFormProps {
  AuthStore: IAuthStore;
  handleLogIn: (
    values: LoginFormSchemaType,
    formikHelpers: FormikHelpers<LoginFormSchemaType>
  ) => Promise<void>;
  handleCreateAccount?: () => void;
  createAccountHref?: string;
  message?: LoginMessage | null;
}

export interface LoginMessage {
  text: string;
  color: AlertColor;
}

export const LoginForm = ({
  message,
  handleLogIn,
  handleCreateAccount,
  createAccountHref,
  AuthStore,
}: LoginFormProps) => {
  const isMobile = useMediaQuery(theme.media.smallDown);
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={handleLogIn}
      validationSchema={loginFormSchema}
    >
      {({ isSubmitting }) => {
        const createAccountButton = handleCreateAccount ? (
          <Button
            variant="secondary"
            size="large"
            disabled={isSubmitting}
            onPress={handleCreateAccount}
          >
            Create new account
          </Button>
        ) : (
          <LinkButton
            variant="secondary"
            size="large"
            href={createAccountHref ?? '/sign-up'}
            disabled={isSubmitting}
          >
            Create new account
          </LinkButton>
        );
        return (
          <>
            <ModalContent>
              <Form id="login-form">
                {message && (
                  <GuidanceCard variant="error">
                    <BodyText>{message.text}</BodyText>
                    {!isMobile ? (
                      <Button
                        size="medium"
                        variant="link"
                        onPress={() => {
                          AuthStore.closeLoginModal();
                          AuthStore.openForgotPasswordModal();
                        }}
                      >
                        Reset password
                      </Button>
                    ) : null}
                  </GuidanceCard>
                )}

                <FormControl
                  name="email"
                  component={TextField}
                  label="Email"
                  autoComplete="email"
                  data-testid="email-input"
                />
                <FormControl
                  name="password"
                  component={RevealableTextField}
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  data-testid="password-input"
                />

                <div
                  css={{
                    alignSelf: 'start',
                  }}
                >
                  <Button
                    variant="link"
                    onPress={() => {
                      AuthStore.closeLoginModal();
                      AuthStore.openForgotPasswordModal();
                    }}
                  >
                    Forgot your password?
                  </Button>
                </div>
              </Form>
            </ModalContent>
            <ModalFooter>
              <Button
                form="login-form"
                type="submit"
                variant="primary"
                size="large"
                disabled={isSubmitting}
                data-testid="login-form-button"
              >
                Log in
              </Button>
              {createAccountButton}
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};
