import { css } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { MambaApiError } from '@headway/api/axios.config';
import { AuthApi } from '@headway/api/resources/AuthApi';
import { trackPageView } from '@headway/shared/utils/analytics';
import { logException } from '@headway/shared/utils/sentry';
import { Modal } from '@headway/ui';
import { Button } from '@headway/ui/Button';
import {
  FieldControl,
  FieldErrorText,
  FieldInputLabel,
} from '@headway/ui/form';
import { FieldPasswordInput } from '@headway/ui/form/FieldPasswordInput';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { theme } from '@headway/ui/theme';
import { closeToast, notifyError } from '@headway/ui/utils/notify';

import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../constants/auth';
import { IAuthStore, IUiStore, withStores } from '../../stores/withStores';

interface ResetPasswordModalProps {
  AuthStore: IAuthStore;
  UiStore: IUiStore;
}

const ResetPasswordModalWithStores: React.FC<ResetPasswordModalProps> = ({
  AuthStore,
}) => {
  const onSubmit = async ({ password }: { password: string }) => {
    if (!AuthStore.resetPasswordToken) {
      notifyError(
        'Cannot reset password. Please click the link in your email again.'
      );
      return;
    }
    const token = AuthStore.resetPasswordToken!;
    try {
      await AuthApi.resetPasswordViaToken(
        { password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      AuthStore.closeResetPasswordModal();
      window.location.assign('/');
    } catch (err) {
      let errorMessage = 'Your password reset link expired or was already used';
      if (
        err instanceof MambaApiError &&
        err?.response?.status != 401 &&
        err?.response?.data?.detail
      ) {
        // Only directly report returned error if not the expected 401
        errorMessage = err.response.data.detail;
      }
      AuthStore.closeResetPasswordModal();
      logException(err);
      notifyError(errorMessage, {
        action: (key) => (
          <>
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                AuthStore.openForgotPasswordModal();
                closeToast(key);
              }}
            >
              Send new link
            </Button>
            <IconButton
              css={{ padding: theme.space.xs }}
              color="inherit"
              disableRipple
              onClick={() => closeToast(key)}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </>
        ),
      });
    }
  };

  React.useEffect(() => {
    trackPageView({ name: 'Reset Password Modal' });
  }, []);

  return (
    <Modal
      open={true}
      onClose={() => AuthStore.closeResetPasswordModal()}
      title="Create a new password"
    >
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .min(
              MIN_PASSWORD_LENGTH,
              `Password must be at least ${MIN_PASSWORD_LENGTH} characters`
            )
            .max(
              MAX_PASSWORD_LENGTH,
              `Passwords cannot be more than ${MAX_PASSWORD_LENGTH} characters`
            )
            .required('Please provide a password'),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], `These passwords don't match`)
            .required('Please confirm the password'),
        })}
        onSubmit={onSubmit}
      >
        {({ isValid, isSubmitting }) => {
          return (
            <SafeFormikForm>
              <div
                css={{
                  display: 'flex',
                  gap: theme.space.sm,
                  flexDirection: 'column',
                }}
              >
                <FieldControl
                  name="password"
                  fullWidth={true}
                  css={{
                    '.MuiFormHelperText-root': {
                      bottom: `-${theme.space.xl} !important`,
                    },
                  }}
                >
                  <FieldInputLabel>New password</FieldInputLabel>
                  <FieldPasswordInput autoComplete="new-password" />
                  <FieldErrorText />
                </FieldControl>
                <FieldControl
                  name="confirmPassword"
                  fullWidth={true}
                  css={{
                    '.MuiFormHelperText-root': {
                      bottom: `-${theme.space.xl} !important`,
                    },
                  }}
                >
                  <FieldInputLabel>Confirm new password</FieldInputLabel>
                  <FieldPasswordInput autoComplete="new-password" />
                  <FieldErrorText />
                </FieldControl>
              </div>
              <div css={buttonContainerStyle}>
                <Button
                  color="primary"
                  type="submit"
                  size="large"
                  variant="contained"
                  css={buttonStyle}
                  disabled={!isValid || isSubmitting}
                >
                  Update password
                </Button>
              </div>
            </SafeFormikForm>
          );
        }}
      </Formik>
    </Modal>
  );
};

const buttonContainerStyle = css`
  display: flex;
  justify-content: flex-end;
  padding-top: ${theme.space.base} !important;
  ${theme.media.smallDown} {
    padding-top: ${theme.space.xl3} !important;
  }
`;
const buttonStyle = css`
  margin-top: ${theme.space.xl2};
  ${theme.media.smallDown} {
    width: 100%;
  }
`;

export const ResetPasswordModal = withStores(ResetPasswordModalWithStores);
