import React from 'react';

export const Eye = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 13.125C10.9862 13.125 11.823 12.7812 12.5105 12.0937C13.198 11.4062 13.5417 10.5694 13.5417 9.58331C13.5417 8.5972 13.198 7.7604 12.5105 7.0729C11.823 6.3854 10.9862 6.04165 10 6.04165C9.01393 6.04165 8.17712 6.3854 7.48962 7.0729C6.80212 7.7604 6.45837 8.5972 6.45837 9.58331C6.45837 10.5694 6.80212 11.4062 7.48962 12.0937C8.17712 12.7812 9.01393 13.125 10 13.125ZM10 11.9166C9.34726 11.9166 8.79518 11.691 8.34379 11.2396C7.8924 10.7882 7.66671 10.2361 7.66671 9.58331C7.66671 8.93053 7.8924 8.37845 8.34379 7.92706C8.79518 7.47567 9.34726 7.24998 10 7.24998C10.6528 7.24998 11.2049 7.47567 11.6563 7.92706C12.1077 8.37845 12.3334 8.93053 12.3334 9.58331C12.3334 10.2361 12.1077 10.7882 11.6563 11.2396C11.2049 11.691 10.6528 11.9166 10 11.9166ZM10 15.8333C7.97226 15.8333 6.13893 15.2569 4.50004 14.1041C2.86115 12.9514 1.63893 11.4444 0.833374 9.58331C1.63893 7.7222 2.86115 6.21526 4.50004 5.06248C6.13893 3.9097 7.97226 3.33331 10 3.33331C12.0278 3.33331 13.8612 3.9097 15.5 5.06248C17.1389 6.21526 18.3612 7.7222 19.1667 9.58331C18.3612 11.4444 17.1389 12.9514 15.5 14.1041C13.8612 15.2569 12.0278 15.8333 10 15.8333ZM10 14.5833C11.6806 14.5833 13.2257 14.1285 14.6355 13.2187C16.0452 12.309 17.1181 11.0972 17.8542 9.58331C17.1181 8.06942 16.0452 6.85762 14.6355 5.9479C13.2257 5.03817 11.6806 4.58331 10 4.58331C8.31949 4.58331 6.77435 5.03817 5.36462 5.9479C3.9549 6.85762 2.87504 8.06942 2.12504 9.58331C2.87504 11.0972 3.9549 12.309 5.36462 13.2187C6.77435 14.1285 8.31949 14.5833 10 14.5833Z"
        fill="#353535"
      />
    </svg>
  );
};

export default Eye;
