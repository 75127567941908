import React from 'react';

import { theme } from '@headway/ui/theme';

import { Link } from '../Link';

export const linkTextStyles = {
  color: theme.color.black,
  marginBottom: theme.space.sm,
  fontSize: theme.fontSize.lg,
  fontFamily: theme.fontFamily.postGrotesk,
  [theme.media.small]: {
    fontSize: theme.fontSize.base,
    marginBottom: theme.space.xs,
  },
};

export const navLinkListStyles = {
  display: 'flex',
  ' a': linkTextStyles,
};

type LinkProps = React.ComponentProps<typeof Link>;

export const NavListLink: React.FC<
  LinkProps & { children: React.ReactNode }
> = ({ children, href, ...rest }) => (
  <li css={navLinkListStyles}>
    <Link href={href} {...rest}>
      {children}
    </Link>
  </li>
);
