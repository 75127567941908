import React from 'react';

interface DropdownGroupContextValue {
  isActive: boolean;
}

export const DropdownGroupContext =
  React.createContext<DropdownGroupContextValue>({
    isActive: false,
  });
