import React, { Children, Component, createRef, RefObject } from 'react';
import { Flipped } from 'react-flip-toolkit';

import FadeContents from './FadeContents';
import {
  AltBackground,
  Caret,
  DropdownBackground,
  DropdownRoot,
  InvertedDiv,
} from './styled';

interface DropdownContainerProps {
  children: React.ReactNode;
  animatingOut: boolean;
  direction: 'left' | 'right';
  duration: number;
}

type DropdownContainerRefs = {
  currentDropdown: HTMLDivElement | null;
  prevDropdown: HTMLDivElement | null;
  altBackground: HTMLDivElement | null;
};

const getFirstDropdownSectionHeight = (el: HTMLElement | null): number => {
  if (
    !el ||
    !el.querySelector ||
    !el.querySelector('*[data-first-dropdown-section]')
  )
    return 0;
  const section = el.querySelector('*[data-first-dropdown-section]');
  return section ? (section as HTMLElement).offsetHeight : 0;
};

const updateAltBackground = ({
  altBackground,
  prevDropdown,
  currentDropdown,
}: DropdownContainerRefs): void => {
  const prevHeight = getFirstDropdownSectionHeight(prevDropdown);
  const currentHeight = getFirstDropdownSectionHeight(currentDropdown);

  const immediateSetTranslateY = (
    el: HTMLElement,
    translateY: number
  ): void => {
    el.style.transform = `translateY(${translateY}px)`;
    el.style.transition = 'transform 0s';
    requestAnimationFrame(() => (el.style.transitionDuration = ''));
  };
  if (altBackground) {
    if (prevHeight) {
      // transition the grey ("alt") background from its previous height to its current height
      immediateSetTranslateY(altBackground, prevHeight);
      requestAnimationFrame(() => {
        altBackground.style.transform = `translateY(${currentHeight}px)`;
      });
    } else {
      // just immediately set the background to the appropriate height
      // since we don't have a stored value
      immediateSetTranslateY(altBackground, currentHeight);
    }
  }
};

class DropdownContainer extends Component<DropdownContainerProps> {
  currentDropdownEl: RefObject<HTMLDivElement> = createRef();
  prevDropdownEl: RefObject<HTMLDivElement> = createRef();
  altBackgroundEl: HTMLDivElement | null = null;

  componentDidMount() {
    updateAltBackground({
      altBackground: this.altBackgroundEl,
      prevDropdown: this.prevDropdownEl.current,
      currentDropdown: this.currentDropdownEl.current,
    });
  }

  render() {
    const { children, direction, animatingOut, duration, ...rest } = this.props;
    const [currentDropdown, prevDropdown] = Children.toArray(children);
    return (
      <DropdownRoot
        direction={direction}
        animatingOut={animatingOut}
        duration={duration}
        {...rest}
      >
        <Flipped flipId="dropdown-caret">
          <Caret />
        </Flipped>
        <Flipped flipId="dropdown">
          <DropdownBackground>
            <Flipped inverseFlipId="dropdown">
              <InvertedDiv>
                <AltBackground
                  ref={(el) => (this.altBackgroundEl = el)}
                  duration={duration}
                />
                <FadeContents
                  direction={direction}
                  duration={duration}
                  ref={this.currentDropdownEl}
                >
                  {currentDropdown}
                </FadeContents>
              </InvertedDiv>
            </Flipped>

            <Flipped inverseFlipId="dropdown" scale>
              <InvertedDiv absolute>
                {prevDropdown && (
                  <FadeContents
                    animatingOut
                    direction={direction}
                    duration={duration}
                    ref={this.prevDropdownEl}
                  >
                    {prevDropdown}
                  </FadeContents>
                )}
              </InvertedDiv>
            </Flipped>
          </DropdownBackground>
        </Flipped>
      </DropdownRoot>
    );
  }
}

export default DropdownContainer;
