import { Switch } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';

import { Modal, ModalProps } from '../Modal';
import {
  getMuiTheme,
  theme as sharedTheme,
  ThemeChoice,
  ThemeContext,
} from '../theme';

export const A11yModal: React.FC<
  React.PropsWithChildren<Omit<ModalProps, 'title'>>
> = (props) => {
  const { theme, setTheme } = React.useContext(ThemeContext);

  const toggleTheme = () => {
    setTheme(
      theme === ThemeChoice.STANDARD
        ? ThemeChoice.HIGH_CONTRAST
        : ThemeChoice.STANDARD
    );
  };

  return (
    <ThemeProvider theme={getMuiTheme(ThemeChoice.HIGH_CONTRAST)}>
      <Modal
        title="Accessibility Options"
        sx={{ '.MuiDialog-paper': { maxWidth: 450 } }}
        {...props}
      >
        <FormControlLabel
          control={
            <Switch
              onChange={toggleTheme}
              checked={theme === ThemeChoice.HIGH_CONTRAST}
            />
          }
          css={{
            color: sharedTheme.color.black,
          }}
          label="High Contrast Mode"
        />
      </Modal>
    </ThemeProvider>
  );
};
