import * as React from 'react';

export const Modalities = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.76 8.44c-.85 0-1.64.23-2.35.61L9.92 4.73c-.2-.35-.78-.35-.98 0l-5.06 8.76c-.1.17-.1.39 0 .57.1.17.29.28.49.28h5.45c.42 2.35 2.47 4.15 4.94 4.15 2.77 0 5.03-2.25 5.03-5.03 0-2.78-2.25-5.03-5.03-5.03v.01zM4.8 13.52l4.63-8.01 2.3 3.98c-1.2.92-1.99 2.35-1.99 3.98v.06H4.8v-.01zm5.75-.06c0-1.32.63-2.49 1.59-3.27l1.92 3.32h-3.5v-.06l-.01.01zm4.21 4.21c-2.02 0-3.71-1.43-4.12-3.34h3.84c.2 0 .39-.11.49-.28.1-.17.1-.39 0-.57l-2.16-3.74c.58-.31 1.24-.5 1.94-.5 2.32 0 4.21 1.89 4.21 4.21 0 2.32-1.89 4.21-4.21 4.21l.01.01z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
