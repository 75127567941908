import { ButtonProps } from '@mui/material/Button';
// eslint-disable-next-line no-restricted-imports
import { LinkProps } from 'next/link';
import React from 'react';

import { Button } from '@headway/ui';

import { Link } from './Link';

/**
 * We need to Omit from the MUI Button the {href} prop
 * as we have to handle routing with Next.js Router
 * so we block the possibility to specify an href.
 */

export type ButtonLinkProps = Omit<ButtonProps, 'href' | 'classes'> &
  Pick<LinkProps, 'href' | 'prefetch'>;

export const ButtonLink = React.forwardRef<ButtonLinkProps, any>(
  ({ ...props }, ref) => <Button component={Link} ref={ref} {...props} />
);
