import * as React from 'react';

export const Languages = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 5.03c-3.84 0-6.96 3.12-6.96 6.96s3.12 6.96 6.96 6.96 6.96-3.12 6.96-6.96S15.84 5.03 12 5.03zm6.13 6.56h-2.45c-.07-2.26-.67-4.2-1.59-5.37 2.25.81 3.88 2.89 4.05 5.37h-.01zM12 18.15c-1.49 0-2.76-2.59-2.86-5.74h5.72c-.1 3.15-1.37 5.74-2.86 5.74zm-2.86-6.56c.1-3.15 1.37-5.74 2.86-5.74 1.49 0 2.76 2.59 2.86 5.74H9.14zm.78-5.37C9 7.39 8.39 9.33 8.33 11.59H5.88a6.14 6.14 0 014.05-5.37h-.01zm-4.05 6.19h2.45c.07 2.26.67 4.2 1.59 5.37a6.155 6.155 0 01-4.05-5.37h.01zm8.21 5.37c.92-1.17 1.53-3.11 1.59-5.37h2.45a6.14 6.14 0 01-4.05 5.37h.01z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
