import * as React from 'react';

export const VideoChat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.75 8a.655.655 0 00-.65.02L16.17 9.9V8.76c0-.57-.46-1.03-1.03-1.03H5.39c-.57 0-1.03.46-1.03 1.03v6.58c0 .57.46 1.03 1.03 1.03h9.74c.57 0 1.03-.46 1.03-1.03V14.2l2.93 1.88c.1.07.22.1.34.1.11 0 .21-.03.31-.08.21-.11.33-.33.33-.56V8.55c0-.23-.13-.45-.33-.56l.01.01zm-4.4 7.35c0 .12-.1.22-.22.22H5.39c-.12 0-.22-.1-.22-.22V8.77c0-.12.1-.22.22-.22h9.74c.12 0 .22.1.22.22v6.58zm3.92-.12l-3.1-1.99v-2.37l3.1-1.99v6.35z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
