import { useState } from 'react';

import { IconButton } from '@headway/helix/IconButton';
import { Eye } from '@headway/helix/icons/Eye';
import { EyeSlash } from '@headway/helix/icons/EyeSlash';
import {
  TextField,
  TextFieldAdornmentEnd,
  TextFieldProps,
} from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { Tooltip, TooltipTrigger } from '@headway/helix/Tooltip';

type RevealableTextFieldProps = {
  showTooltip?: string;
  hideTooltip?: string;
} & Omit<TextFieldProps, 'adornment'>;

const RevealableTextField = ({
  showTooltip,
  hideTooltip,
  ...props
}: RevealableTextFieldProps) => {
  // STATE
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      adornment={
        <TextFieldAdornmentEnd>
          <TooltipTrigger>
            <IconButton
              variant="transparent"
              onPress={() => setShowPassword((prev) => !prev)}
              size="small"
            >
              {showPassword ? (
                <EyeSlash width="100%" fill={theme.color.system.textBlack} />
              ) : (
                <Eye width="100%" fill={theme.color.system.textBlack} />
              )}
            </IconButton>
            <Tooltip>
              {showPassword
                ? hideTooltip ?? 'Hide password'
                : showTooltip ?? 'Show password'}
            </Tooltip>
          </TooltipTrigger>
        </TextFieldAdornmentEnd>
      }
      {...props}
      type={showPassword ? 'text' : 'password'}
    />
  );
};

export { RevealableTextField };
