import {
  AttachMoneyTwoTone,
  HealingOutlined,
  HelpOutlineTwoTone,
  SearchTwoTone,
} from '@mui/icons-material';
import sortBy from 'lodash/sortBy';
import React from 'react';

import { useMarkets } from '@headway/ui/providers/MarketProvider';

import { getProvidersSearchPath } from '../../../hooks/useProvidersSearch';
import { IAuthStore, IUiStore, withStores } from '../../../stores/withStores';
import {
  LandingPageInteraction,
  SearchEntry,
  trackProviderSearchCompleted,
  trackProviderSearchStarted,
} from '../../../utils/searchAnalyticEvents';
import { DropdownList } from './components/DropdownList';
import { DropdownListLink } from './components/DropdownListLink';
import { DropdownSection } from './components/DropdownSection';
import { DropdownTitle } from './components/DropdownTitle';

type Props = {
  AuthStore: IAuthStore;
  UiStore: IUiStore;
};

const GetCareDropdownImpl: React.FC<Props> = ({ AuthStore }) => {
  const { liveMarkets } = useMarkets();

  return (
    <div css={{ maxHeight: '80vh' }}>
      {liveMarkets?.length > 1 && (
        <DropdownSection
          data-first-dropdown-section
          css={{
            maxHeight: '40vh',
            overflow: 'auto',
          }}
        >
          <DropdownTitle>Find a therapist</DropdownTitle>
          <DropdownList>
            {sortBy(liveMarkets, 'displayName').map((market) => (
              <div
                key={market.id}
                onClick={() => {
                  trackProviderSearchStarted({
                    context: {
                      interaction: LandingPageInteraction.Location,
                      source: SearchEntry.GetCareHeader,
                    },
                    filters: {
                      state: market.state,
                    },
                  });
                  trackProviderSearchCompleted({
                    context: {
                      source: SearchEntry.GetCareHeader,
                    },
                    filters: {
                      state: market.state,
                    },
                  });
                }}
              >
                <DropdownListLink
                  key={market.slug}
                  href={getProvidersSearchPath({ marketSlug: market.slug })}
                >
                  {market.displayName}
                </DropdownListLink>
              </div>
            ))}
          </DropdownList>
        </DropdownSection>
      )}
      <DropdownSection
        data-first-dropdown-section={
          !liveMarkets.length || !(liveMarkets.length < 2)
        }
      >
        <DropdownList>
          {liveMarkets?.length < 2 && (
            <DropdownListLink
              href={getProvidersSearchPath()}
              icon={SearchTwoTone}
            >
              Find a therapist
            </DropdownListLink>
          )}
          <DropdownListLink href="/#how-it-works" icon={HelpOutlineTwoTone}>
            How it works
          </DropdownListLink>
          <DropdownListLink
            href="/does-my-insurance-cover-therapy"
            icon={AttachMoneyTwoTone}
          >
            Does my insurance cover therapy?
          </DropdownListLink>
        </DropdownList>
      </DropdownSection>
    </div>
  );
};

const GetCareDropdown = withStores(GetCareDropdownImpl);

export default GetCareDropdown;
