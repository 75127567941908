import React from 'react';

import { theme } from '@headway/ui/theme';

export const DropdownSection: React.FC<React.PropsWithChildren> = (props) => (
  <div
    css={{
      padding: theme.space.lg,
      position: 'relative',
      zIndex: 1,
    }}
    {...props}
  />
);
