import * as React from 'react';

export const Ethnicities = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.98 9.2c0-2.37-1.93-4.3-4.3-4.3-1.12 0-2.13.44-2.9 1.15-.77-.7-1.78-1.15-2.9-1.15-2.37 0-4.3 1.93-4.3 4.3 0 1.11.44 2.12 1.13 2.88-.7.76-1.13 1.77-1.13 2.88 0 2.37 1.93 4.3 4.3 4.3 1.12 0 2.13-.44 2.9-1.15.77.7 1.78 1.15 2.9 1.15 2.37 0 4.3-1.93 4.3-4.3 0-1.11-.44-2.12-1.13-2.88.7-.76 1.13-1.77 1.13-2.88zm-.81 0c0 .9-.35 1.72-.92 2.34-.72-.54-1.6-.87-2.57-.87-.73 0-1.41.2-2.01.52.31-.6.51-1.27.51-1.99 0-.96-.33-1.83-.86-2.55a3.44 3.44 0 012.36-.93c1.92 0 3.48 1.56 3.48 3.48h.01zm-6.38 7.69c-.37-.55-.58-1.21-.58-1.93s.22-1.37.58-1.93c.37.55.58 1.21.58 1.93s-.22 1.37-.58 1.93zm0-9.61c.37.55.58 1.21.58 1.93s-.22 1.37-.58 1.93c-.37-.55-.58-1.21-.58-1.93s.22-1.37.58-1.93zm4.85 4.81c-.56.38-1.23.6-1.95.6s-1.4-.22-1.95-.6c.56-.38 1.23-.6 1.95-.6s1.4.22 1.95.6zM8.89 5.72c.91 0 1.74.36 2.36.93-.53.71-.86 1.59-.86 2.55 0 .72.2 1.39.51 1.99a4.26 4.26 0 00-2.01-.52c-.97 0-1.85.33-2.57.87-.56-.62-.92-1.43-.92-2.34 0-1.92 1.56-3.48 3.48-3.48h.01zm1.95 6.37c-.56.38-1.23.6-1.95.6s-1.4-.22-1.95-.6c.56-.38 1.23-.6 1.95-.6s1.4.22 1.95.6zM5.4 14.97c0-.9.35-1.72.92-2.34.72.54 1.6.87 2.57.87.73 0 1.41-.2 2.01-.52-.31.6-.51 1.27-.51 1.99 0 .96.33 1.83.86 2.55-.62.57-1.45.93-2.36.93-1.92 0-3.48-1.56-3.48-3.48H5.4zm9.28 3.48c-.91 0-1.74-.36-2.36-.93.53-.72.86-1.59.86-2.55 0-.72-.2-1.39-.51-1.99.6.32 1.28.52 2.01.52.97 0 1.85-.33 2.57-.87.56.62.92 1.43.92 2.34 0 1.92-1.56 3.48-3.48 3.48h-.01z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
