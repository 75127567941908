import uniq from 'lodash/uniq';
import { ParsedUrlQuery } from 'querystring';

import { FrontEndCarrierReadByUser } from '@headway/api/models/FrontEndCarrierReadByUser';
import { FrontEndCarrierApi } from '@headway/api/resources/FrontEndCarrierApi';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { useQuery, UseQueryOptions } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';

import { useRouter } from './useRouter';

const KNOWN_FRONT_END_CARRIER_IDS_LOCAL_STORAGE_KEY = 'knownFrontEndCarrierIds';
const FRONT_END_CARRIER_ID_QUERY_PARAM = 'frontEndCarrierId';
const SEARCHABLE_FRONT_END_CARRIERS_CACHE_KEY = 'searchable-front-end-carriers';

const getFrontEndCarrierIdFromQueryParams = (
  queryParams: ParsedUrlQuery
): number | undefined => {
  const rawFrontEndCarrierId = queryParams[FRONT_END_CARRIER_ID_QUERY_PARAM];

  if (!rawFrontEndCarrierId) return undefined;

  if (Array.isArray(rawFrontEndCarrierId)) {
    logException('Unexpected array for frontEndCarrierId');
    return undefined;
  }

  const frontEndCarrierId = parseInt(rawFrontEndCarrierId, 10);

  if (isNaN(frontEndCarrierId)) return;

  return frontEndCarrierId;
};

class NonArrayJsonValueError extends Error {
  actual: unknown;
  constructor(message: string, actual: unknown) {
    super(message);

    this.actual = actual;
  }
}

const getUniqueNumericValuesFromJsonArray = (
  jsonArray: string
): Array<number> => {
  const parsed: unknown | undefined = JSON.parse(jsonArray);

  if (!Array.isArray(parsed)) {
    throw new NonArrayJsonValueError(
      'Expected JSON value to be an array',
      parsed
    );
  }

  return uniq(
    parsed.filter(
      (value) => typeof value === 'number' && Number.isFinite(value)
    )
  );
};

const useStoredKnownFrontEndCarrierIds = (): [
  Array<number>,
  (id: number) => void,
] => {
  const [existingKnownFECsStr, setInLocalStorage] = useLocalStorage(
    KNOWN_FRONT_END_CARRIER_IDS_LOCAL_STORAGE_KEY
  );

  const existingKnownFECs: Array<number> = existingKnownFECsStr
    ? getUniqueNumericValuesFromJsonArray(existingKnownFECsStr)
    : [];

  const addKnownFrontEndCarrierId = (id: number): void => {
    if (existingKnownFECs.includes(id)) {
      return;
    }

    const newKnownFrontEndCarrierIds = [...existingKnownFECs, id];
    setInLocalStorage(JSON.stringify(newKnownFrontEndCarrierIds));
  };

  return [existingKnownFECs, addKnownFrontEndCarrierId];
};

const useKnownFrontEndCarrierIds = () => {
  const [storedFrontEndCarriers, addKnown] = useStoredKnownFrontEndCarrierIds();
  const { query } = useRouter();
  const frontEndCarrierId = getFrontEndCarrierIdFromQueryParams(query);

  if (frontEndCarrierId) {
    addKnown(frontEndCarrierId);
  }

  return storedFrontEndCarriers;
};

type GetSearchableFrontEndCarriersQueryParams = Parameters<
  typeof FrontEndCarrierApi.getSearchableFrontEndCarriers
>[0];
type QueryOptions = UseQueryOptions<
  Array<FrontEndCarrierReadByUser>,
  unknown,
  Array<FrontEndCarrierReadByUser>,
  [
    typeof SEARCHABLE_FRONT_END_CARRIERS_CACHE_KEY,
    GetSearchableFrontEndCarriersQueryParams,
  ]
>;

function useSearchableFrontEndCarriersQuery(options?: QueryOptions) {
  const knownFrontEndCarrierIds = useKnownFrontEndCarrierIds();
  const queryParams: GetSearchableFrontEndCarriersQueryParams = {};

  if (knownFrontEndCarrierIds) {
    queryParams.known_front_end_carrier_ids = knownFrontEndCarrierIds;
  }
  return useQuery(
    [SEARCHABLE_FRONT_END_CARRIERS_CACHE_KEY, queryParams],
    () => FrontEndCarrierApi.getSearchableFrontEndCarriers(queryParams),
    { staleTime: Infinity, ...options }
  );
}

export { useKnownFrontEndCarrierIds, useSearchableFrontEndCarriersQuery };
