import { ButtonProps, MenuItem, MenuItemProps } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { LinkProps } from 'next/link';
import React, { useContext } from 'react';

import { Button } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { Link } from '../../../Link';
import { DropdownGroupContext } from './DropdownGroupContext';

type DropdownListLinkProps = MenuItemProps &
  LinkProps &
  ButtonProps & {
    icon?: React.ComponentType;
  };

export const DropdownListLink = ({
  children,
  href,
  icon: Icon,
  ...rest
}: DropdownListLinkProps) => {
  const { isActive } = useContext(DropdownGroupContext);

  return (
    <li>
      <Button
        role="menuitem"
        tabIndex={isActive ? 0 : -1}
        aria-label={rest['aria-label'] ? rest['aria-label'] : children}
        href={href}
        component={Link}
        startIcon={
          Icon && (
            <span
              css={{
                background: theme.color.primaryBackground,
                borderRadius: '50%',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: theme.space.xs,
              }}
            >
              <Icon
                css={{ color: theme.color.primary, width: 20, height: 20 }}
              />
            </span>
          )
        }
        size="large"
        fullWidth={true}
        color="gray"
      >
        {children}
      </Button>
    </li>
  );
};
