// @mui/material components
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@headway/ui/Button';
import { Modal } from '@headway/ui/Modal';

import { withStores } from '../../stores/withStores';

class AlertImpl extends React.Component {
  render() {
    const { UiStore } = this.props;

    return UiStore.showAlert ? (
      <Modal
        open={UiStore.showAlert}
        keepMounted
        onClose={() => {
          if (UiStore.alertCbOnClose) {
            UiStore.alertCbOnClose();
          }
          UiStore.hideAlert();
        }}
        title={UiStore.alertMessageTitle}
        dividers={false}
        actions={
          UiStore.alertButtonText != null && (
            <Button
              variant="contained"
              size="large"
              color={UiStore.alertButtonColor || 'gray'}
              onClick={() => {
                if (UiStore.alertButtonOnClick) {
                  UiStore.alertButtonOnClick();
                }
                if (UiStore.alertCbOnClose) {
                  UiStore.alertCbOnClose();
                }
                UiStore.hideAlert();
              }}
              data-testid="alertButton"
            >
              {UiStore.alertButtonText}
            </Button>
          )
        }
        sx={{
          '.MuiPaper-root': {
            // 'sm' size is just barely too small, but 'md' is too big, so configuring manually
            maxWidth: '700px',
          },
        }}
      >
        <div>{UiStore.alertMessage}</div>
      </Modal>
    ) : (
      <React.Fragment></React.Fragment>
    );
  }
}

const Alert = withStores(AlertImpl);

Alert.propTypes = {
  className: PropTypes.string,
};

export default Alert;
