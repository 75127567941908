import { Avatar, Button, Divider, Menu, MenuItem } from '@mui/material';
import gravatarUrl from 'gravatar-url';
import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { AuthApi } from '@headway/api/resources/AuthApi';
import { Badge } from '@headway/helix/Badge';
import { useAppointmentReadiness } from '@headway/shared/hooks/useAppointmentReadiness';
import {
  formatPatientName,
  shouldDisplayAccountNotificationBadge,
} from '@headway/shared/utils/patient';
import { useSolvvyController } from '@headway/ui/Solvvy';
import { theme } from '@headway/ui/theme';

import { useBillingEvents } from '../../contexts';
import { useRouter } from '../../hooks/useRouter';
import { Link } from '../Link';
import { NotificationBadge } from './NotificationBadge';

interface AccountMenuProps {
  user: any;
  logout: () => void;
  impersonatingUser?: UserRead;
}

export const AccountMenu: React.FC<AccountMenuProps> = ({
  user,
  logout,
  impersonatingUser,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  const solvvyController = useSolvvyController();
  const openSolvvy = () => {
    if (solvvyController) {
      solvvyController.open();
      handleClose();
    }
  };
  const router = useRouter();
  const appointmentReadinessQuery = useAppointmentReadiness(user);
  const appointmentReadiness = appointmentReadinessQuery.data;

  const {
    partitionedBillingEvents: { needsAttention: billingEventsAwaitingAction },
  } = useBillingEvents();

  const showAccountNotificationBadge =
    appointmentReadiness &&
    shouldDisplayAccountNotificationBadge(appointmentReadiness);

  const isImpersonator =
    !!impersonatingUser && impersonatingUser.id !== user.id;

  return (
    <React.Fragment>
      <Button
        css={{
          borderRadius: '50%',
          padding: 0,
          minWidth: 0,
        }}
        aria-controls="user-menu"
        aria-haspopup="true"
        aria-expanded={!!anchorEl}
        data-testid="avatar-button"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        {...rest}
      >
        <span className="sr-only">Account menu</span>
        <Avatar
          src={gravatarUrl(user.email, {
            size: 200,
            rating: 'g',
            default: '404',
          })}
          css={{
            border: `2px solid ${theme.color.primaryBackground}`,
            ':hover': {
              border: `2px solid ${theme.color.primary}`,
            },
            background: theme.color.primaryBackground,
            color: theme.color.primary,
          }}
        >
          {formatPatientName(user, {
            firstInitial: true,
            lastInitial: true,
            initialsWithoutPeriodOrSpace: true,
          }) || null}
        </Avatar>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        css={{
          '.MuiList-root': {
            paddingTop: 0,
          },
        }}
      >
        <div css={{ padding: theme.space.base }} tabIndex={-1}>
          {formatPatientName(user) ? (
            <div
              css={{
                fontFamily: theme.fontFamily.canelaText,
                fontSize: theme.fontSize.base,
                color: theme.color.primary,
                marginBottom: theme.space.xs,
              }}
            >
              {formatPatientName(user)}
            </div>
          ) : null}
          <div
            css={{
              fontFamily: theme.fontFamily.postGrotesk,
            }}
          >
            {user.email}
          </div>
        </div>
        <Divider css={{ marginBottom: theme.space.xs }} />
        <MenuItem component={Link} href="/account" data-testid="account-link">
          Account
          <NotificationBadge hidden={!showAccountNotificationBadge} />
        </MenuItem>
        <MenuItem component={Link} href="/appointments/upcoming">
          Appointments
        </MenuItem>
        <MenuItem component={Link} href="/billing" data-testid="billingLink">
          Billing
          <>
            <div className="ml-2">
              <Badge variant="info">New</Badge>
            </div>
            <div className="self-start">
              <NotificationBadge hidden={!billingEventsAwaitingAction.length} />
            </div>
          </>
        </MenuItem>
        <MenuItem component={Link} href="/messages" data-testid="messagesLink">
          Messages
        </MenuItem>
        <Divider css={{ marginBottom: theme.space.xs }} />
        {solvvyController && (
          <MenuItem onClick={openSolvvy}>Contact Us</MenuItem>
        )}
        <MenuItem
          component={(props: any) => (
            <Link {...props} target="_blank" rel="noreferrer" />
          )}
          href="/support"
        >
          Help Center
        </MenuItem>
        <Divider />
        {isImpersonator && (
          <MenuItem
            onClick={async () => {
              await AuthApi.undoImpersonateUser();

              // changes won't propagate properly unless we fully reload
              router.push('/');
              window.location.reload();
            }}
          >
            Stop Spoofing
          </MenuItem>
        )}
        <MenuItem tabIndex={0} onClick={logout}>
          Log Out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
