import React from 'react';

import { Modal } from '@headway/helix/Modal';
import { useMutation } from '@headway/shared/react-query';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { logException } from '@headway/shared/utils/sentry';

import { useRouter } from '../../hooks/useRouter';
import { IAuthStore, withStores } from '../../stores/withStores';
import { isUnauthenticatedError } from '../../utils/query';
import { LoginForm, LoginFormSchemaType, LoginMessage } from './LogInForm';

interface LoginModalProps {
  AuthStore: IAuthStore;
}

const LoginModalImpl = ({ AuthStore }: LoginModalProps) => {
  const router = useRouter();
  const mutation = useMutation(
    async (values: LoginFormSchemaType) => {
      await AuthStore.logIn({
        email: values.email,
        password: values.password,
      });
    },
    {
      onSuccess() {
        trackEvent({ name: 'Log In Completed' });
        AuthStore.closeLoginModal();
        if (window.location.pathname === '/') {
          window.location.assign('/account');
        }
      },
      onError(err) {
        if (!isUnauthenticatedError(err)) {
          logException(err);
        }
      },
    }
  );

  const handleClose = () => {
    AuthStore.closeLoginModal();
    const gatedRedirectLocation = AuthStore.gatedRedirectLocation;
    if (gatedRedirectLocation) {
      AuthStore.gatedRedirectLocation = undefined;
      router.push(gatedRedirectLocation);
    }
  };

  const handleCreateAccount = () => {
    AuthStore.closeLoginModal();
    AuthStore.openSignupModal();
  };

  React.useEffect(() => {
    trackPageView({ name: 'Login Modal' });
    trackEvent({ name: 'Log In Started' });
  }, []);

  const message =
    mutation.status === 'error'
      ? {
          text: 'We couldn’t log you in. Double-check your email and password and try again.',
          color: 'error' as LoginMessage['color'],
        }
      : undefined;

  return (
    <Modal isOpen={true} onDismiss={handleClose} title="Log in">
      <LoginForm
        message={message}
        handleLogIn={async (values, formik) => {
          await mutation.mutateAsync(values);
          formik.setSubmitting(false);
        }}
        handleCreateAccount={handleCreateAccount}
        AuthStore={AuthStore}
      />
    </Modal>
  );
};

export const LoginModal = withStores(LoginModalImpl);
