import {
  ImportContactsOutlined,
  LocalHospitalOutlined,
} from '@mui/icons-material';
import React from 'react';

import { DropdownList } from './components/DropdownList';
import { DropdownListLink } from './components/DropdownListLink';
import { DropdownSection } from './components/DropdownSection';

const ForHealthPlansDropdown = () => {
  return (
    <div>
      <DropdownSection data-first-dropdown-section>
        <DropdownList>
          <DropdownListLink
            href="/for-health-plans"
            icon={ImportContactsOutlined}
            aria-label="Learn more about health plans"
          >
            Learn more
          </DropdownListLink>
          <DropdownListLink
            href="mailto:partnerships@headway.co"
            icon={LocalHospitalOutlined}
          >
            Partner with us
          </DropdownListLink>
        </DropdownList>
      </DropdownSection>
    </div>
  );
};

export default ForHealthPlansDropdown;
