import styled from '@emotion/styled';

import { theme } from '@headway/ui/theme';

export const DropdownTitle = styled.p({
  fontFamily: theme.fontFamily.postGrotesk,
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.fontSize.sm,
  textTransform: 'uppercase',
  color: theme.color.gray,
  marginBottom: theme.space.xs,
  marginTop: 0,
});
