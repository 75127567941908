import {
  AssignmentOutlined,
  GroupAddOutlined,
  ImportContactsOutlined,
  TouchAppOutlined,
} from '@mui/icons-material';

import { InPerson as InPersonIcon } from '@headway/icons/dist/helix/provider';
import { REFERRAL_BONUS_AMOUNT } from '@headway/shared/constants/referrals';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { theme } from '@headway/ui/theme';

import { DropdownList } from './components/DropdownList';
import { DropdownListLink } from './components/DropdownListLink';
import { DropdownSection } from './components/DropdownSection';

const ForProvidersDropdown = () => {
  const includeResourceCenterLink = useFlag('resourceCenterNavLink', false);

  return (
    <div>
      <DropdownSection data-first-dropdown-section>
        <DropdownList>
          <DropdownListLink
            href="/for-providers"
            icon={ImportContactsOutlined}
            aria-label="Learn more about providers"
          >
            Learn more
          </DropdownListLink>
          <DropdownListLink
            href="/for-providers#get-started"
            icon={TouchAppOutlined}
          >
            Join Headway
          </DropdownListLink>
          <DropdownListLink href="/for-providers/refer" icon={GroupAddOutlined}>
            Refer a provider, get ${REFERRAL_BONUS_AMOUNT}
          </DropdownListLink>
          <DropdownListLink
            href={process.env.NEXT_PUBLIC_SIGMUND_URL || ''}
            icon={AssignmentOutlined}
          >
            Provider Portal
          </DropdownListLink>
          {includeResourceCenterLink && (
            <DropdownListLink
              href="/resources/all"
              icon={NormalizedInPersonIcon}
            >
              Provider Resource Center
            </DropdownListLink>
          )}
        </DropdownList>
      </DropdownSection>
    </div>
  );
};

function NormalizedInPersonIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <InPersonIcon
      css={{
        '& path': {
          fill: theme.color.primary,
          fillOpacity: 1,
          transform: 'scale(1.5)',
          transformOrigin: 'center center',
        },
      }}
      {...props}
    />
  );
}

export default ForProvidersDropdown;
