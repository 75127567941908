import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { forwardRef, ReactNode } from 'react';

import { promoteLayer } from './utils';

// Define types for FadeContainer props
interface FadeContainerProps {
  duration: number;
  direction?: 'right' | 'left';
  animatingOut?: boolean;
}

// Define types for FadeContents props
interface FadeContentsProps extends FadeContainerProps {
  children?: ReactNode;
}

const getFadeContainerKeyFrame = ({
  animatingOut,
  direction,
}: FadeContainerProps) => {
  if (!direction) return;
  return keyframes`
  to {
    transform: translateX(0px);
    opacity: ${animatingOut ? 0 : 1};
  }
`;
};

const FadeContainer = styled.div<FadeContainerProps>`
  ${promoteLayer}
  animation-name: ${getFadeContainerKeyFrame};
  animation-duration: ${(props) => props.duration}ms;
  animation-fill-mode: forwards;
  opacity: ${(props) => (props.direction && !props.animatingOut ? 0 : 1)};
  top: 0;
  left: 0;
`;

const FadeContents = forwardRef<HTMLDivElement, FadeContentsProps>(
  ({ children, duration, animatingOut, direction }, ref) => (
    <FadeContainer
      // prevent screen readers from reading out hidden content
      aria-hidden={animatingOut}
      animatingOut={animatingOut}
      direction={direction}
      duration={duration}
      ref={ref}
    >
      {children}
    </FadeContainer>
  )
);

export default FadeContents;
