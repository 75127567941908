import * as React from 'react';

export const Gender = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5 12.06h-6.07l4.29-4.29a.41.41 0 00-.58-.58l-4.29 4.29V5.41c0-.23-.18-.41-.41-.41-.23 0-.41.18-.41.41v6.64H5.41c-.22 0-.41.18-.41.41 0 .23.18.41.41.41h6.07l-4.29 4.29a.41.41 0 00.58.58l4.29-4.29v6.07c0 .22.18.41.41.41.23 0 .41-.18.41-.41v-6.64h6.64c.22 0 .41-.18.41-.41 0-.23-.18-.41-.41-.41h-.02z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
