import * as React from 'react';

export const Education = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.91 10.14l-7.57-5.68a.57.57 0 00-.68 0l-7.58 5.68a.564.564 0 00.01.9l7.57 5.68c.1.08.22.11.34.11.12 0 .24-.04.34-.11l7.57-5.68c.14-.11.23-.28.23-.45 0-.17-.08-.35-.23-.45zM12 15.96l-7.16-5.37L12 5.22l7.16 5.37L12 15.96zm5-.65v.55c0 .35-.17.69-.45.9l-4.03 3.02a.87.87 0 01-.53.18c-.19 0-.37-.06-.53-.17l-4.03-3.02c-.28-.21-.45-.55-.45-.9v-.55c0-.22.18-.41.41-.41.23 0 .41.18.41.41v.55c0 .1.05.19.12.25l4.03 3.02 4.11-3.02c.08-.06.12-.15.12-.25v-.55c0-.22.18-.41.41-.41.23 0 .41.18.41.41v-.01zm1.83-1.99v3.8c0 .23-.18.41-.41.41-.23 0-.41-.18-.41-.41v-3.8c0-.22.18-.41.41-.41.23 0 .41.18.41.41z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
