import { keyframes } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { PaperProps } from '@mui/material';
import React from 'react';

import { theme } from '@headway/ui/theme';

import { promoteLayer } from './utils';

interface DropdownRootProps {
  duration: number;
  animatingOut?: boolean;
  direction?: 'left' | 'right';
}

interface AltBackgroundProps {
  duration: number;
}

interface InvertedDivProps {
  absolute?: boolean;
}

type DropdownBackgroundProps = PaperProps;

const getDropdownRootKeyFrame = ({
  animatingOut,
  direction,
}: DropdownRootProps) => {
  if (!animatingOut && direction) return null;
  return keyframes`
  from {
    transform: ${animatingOut ? 'rotateX(0)' : 'rotateX(-15deg)'};
    opacity: ${animatingOut ? 1 : 0};
  }
  to {
    transform: ${animatingOut ? 'rotateX(-15deg)' : 'rotateX(0)'};
    opacity: ${animatingOut ? 0 : 1};
  }
`;
};

export const DropdownRoot = styled.div<DropdownRootProps>`
  transform-origin: 0 0;
  ${promoteLayer}
  animation-name: ${getDropdownRootKeyFrame};
  animation-duration: ${(props) => props.duration}ms;
  /* use 'forwards' to prevent flicker on leave animation */
  animation-fill-mode: forwards;
  /* flex styles will center the caret child component */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -10px;
`;

export const Caret = styled.div`
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent ${theme.color.white};
  /* make sure it's above the main dropdown container so now box-shadow bleeds over it */
  z-index: 1;
  position: relative;
  /* prevent any gap in between caret and main dropdown */
  top: 1px;
`;

export const DropdownBackground = (props: DropdownBackgroundProps) => (
  <Paper
    elevation={20}
    css={css`
      transform-origin: 0 0;
      background-color: ${theme.color.white};
      overflow: hidden;
      position: relative;
      border-radius: 8px;
      ${promoteLayer}
    `}
    {...props}
  />
);

export const AltBackground = styled.div<AltBackgroundProps>`
  background-color: ${theme.color.background};
  width: 300%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  transform-origin: 0 0;
  z-index: 0;
  transition: transform ${(props) => props.duration}ms;
`;

export const InvertedDiv = styled.div<InvertedDivProps>`
  ${promoteLayer}
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  &:first-of-type {
    z-index: 1;
  }
  &:not(:first-of-type) {
    z-index: -1;
  }
`;
