import styled from '@emotion/styled';

import { theme as helixTheme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';
import { Button } from '@headway/ui';
import { theme } from '@headway/ui/theme';

const HeaderItemEl = styled.li`
  position: relative;
`;

const DropdownSlot = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  perspective: 1500px;
  z-index: 1000;
`;

interface HeaderItemProps {
  title: string;
  children: React.ReactNode;
  index: number;
  currentIndex: number;
  onMouseEnter: (index: number) => void;
  onMouseLeave: (index: number) => void;
}

export function HeaderItem(props: HeaderItemProps) {
  const onMouseEnter = () => {
    props.onMouseEnter(props.index);
  };

  const onClick = () => {
    if (props.index === props.currentIndex) {
      props.onMouseLeave(props.index);
    } else {
      props.onMouseEnter(props.index);
    }
  };

  const onBlur = (e: React.FocusEvent) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      props.onMouseLeave(props.index);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'Escape') {
      props.onMouseLeave(props.index);
      e.preventDefault();
    }
  };

  const { title, children, index, currentIndex } = props;

  const isBelowDesktop = useMediaQuery(
    helixTheme.__futureMedia.below('desktop')
  );

  return (
    <HeaderItemEl
      role="none"
      onMouseEnter={onMouseEnter}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    >
      <Button
        role="menuitem"
        aria-haspopup="true"
        aria-expanded={currentIndex === index}
        onClick={onClick}
        disableRipple={true}
        color="gray"
        size={isBelowDesktop ? 'medium' : 'large'}
        css={{
          background: 'transparent',
          border: '1px solid transparent',
          display: 'flex',
          justifyContent: 'center',
          transition: 'opacity 250ms',
          cursor: 'default',
          position: 'relative',
          paddingLeft: theme.space.lg,
          paddingRight: theme.space.lg,
          zIndex: '2',
          letterSpacing: '.2px',
          ' .MuiButton-base': {
            color: theme.color.darkGray,
          },
          ':hover': {
            background: 'transparent',
            ' .MuiButton-base': {
              color: theme.color.black,
            },
          },
        }}
      >
        {title}
      </Button>

      <DropdownSlot>{children}</DropdownSlot>
    </HeaderItemEl>
  );
}
