import styled from '@emotion/styled';

export const DropdownList = styled.ul({
  margin: 0,
  listStyle: 'none',
  paddingLeft: 0,
  ' li': {
    display: 'flex',
  },
  ' .MuiButton-root': {
    justifyContent: 'flex-start',
    whiteSpace: 'nowrap',
  },
});
