import * as React from 'react';

export const Phone = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6 4.38H8.39c-.66 0-1.2.54-1.2 1.2v12.85c0 .66.54 1.2 1.2 1.2h7.21c.66 0 1.2-.54 1.2-1.2V5.58c0-.66-.54-1.2-1.2-1.2zm.39 14.05c0 .21-.17.38-.39.38H8.39a.38.38 0 01-.38-.38V5.58c0-.21.17-.38.38-.38h7.21c.21 0 .39.17.39.38v12.85zm-1.62-.88c0 .22-.18.41-.41.41h-3.92c-.22 0-.41-.18-.41-.41 0-.23.18-.41.41-.41h3.92c.22 0 .41.18.41.41z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
