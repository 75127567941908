import { SettingsAccessibility } from '@mui/icons-material';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import React from 'react';

import { theme } from '@headway/ui/theme';

import { A11yModal } from '.';

export const A11yModalButton: React.FC<
  React.PropsWithChildren<IconButtonProps>
> = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Tooltip title="Accessibility options">
        <IconButton
          aria-label="Accessibility options"
          size="large"
          onClick={handleOpen}
          {...props}
          sx={{
            backgroundColor: theme.highContrastColors.primary,
            color: theme.highContrastColors.primaryBackground,
            '&:hover': {
              backgroundColor: theme.highContrastColors.primary,
            },
            ...(props.sx || {}),
          }}
        >
          <SettingsAccessibility />
        </IconButton>
      </Tooltip>
      <A11yModal open={open} onClose={handleClose} />
    </>
  );
};
