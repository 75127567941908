import { AlertColor } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';

import { IAuthStore } from '../../stores/withStores';

const forgotPasswordFormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid email address')
    .required('Email is required'),
});

export type ForgotPasswordFormSchemaType = Yup.InferType<
  typeof forgotPasswordFormSchema
>;

interface ForgotPasswordMessage {
  text: string;
  color: AlertColor;
}

interface ForgotPasswordFormProps {
  AuthStore: IAuthStore;
  message?: ForgotPasswordMessage | null;
  handleSubmit: (
    values: ForgotPasswordFormSchemaType,
    actions: FormikHelpers<ForgotPasswordFormSchemaType>
  ) => void;
  handleClose: () => void;
}

const ForgetPasswordForm = (props: ForgotPasswordFormProps) => {
  const { message, handleSubmit, handleClose } = props;
  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={handleSubmit}
      validationSchema={forgotPasswordFormSchema}
    >
      {({ isSubmitting }) => (
        <>
          <ModalContent>
            <Form
              id="forgot-password-form"
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing.x4,
              }}
            >
              {message && message.color === 'error' && (
                <GuidanceCard variant="error">{message.text}</GuidanceCard>
              )}
              {message && message.color === 'success' ? (
                <GuidanceCard variant="info">{message.text}</GuidanceCard>
              ) : (
                <>
                  <GuidanceCard variant="info">
                    {message && message.color === 'success'
                      ? message.text
                      : `Enter the email address associated with your Headway account, and
              we’ll send an email with instructions to reset your password.`}
                  </GuidanceCard>

                  <FormControl
                    name="email"
                    component={TextField}
                    label="Email"
                  />
                </>
              )}
            </Form>
          </ModalContent>
          <ModalFooter>
            {message && message.color === 'success' ? (
              <Button variant="secondary" size="large" onPress={handleClose}>
                Close
              </Button>
            ) : (
              <Button
                type="submit"
                form="forgot-password-form"
                variant="primary"
                size="large"
                disabled={isSubmitting}
              >
                Continue
              </Button>
            )}
          </ModalFooter>
        </>
      )}
    </Formik>
  );
};

export default ForgetPasswordForm;
