import { FormikHelpers } from 'formik';
import moment from 'moment';
import React from 'react';

import { CompleteAccountRequest } from '@headway/api/models/CompleteAccountRequest';
import { SignupRequest } from '@headway/api/models/SignupRequest';
import { UserRead } from '@headway/api/models/UserRead';
import { UserSmsOptOutType } from '@headway/api/models/UserSmsOptOutType';
import { AuthApi } from '@headway/api/resources/AuthApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Modal } from '@headway/helix/Modal';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError } from '@headway/ui/utils/notify';

import { useRouter } from '../../hooks/useRouter';
import { IAuthStore, IUiStore, withStores } from '../../stores/withStores';
import {
  isDuplicateRequestError,
  isUnauthenticatedError,
} from '../../utils/query';
import {
  CreateAccountForm,
  CreateAccountFormValues,
} from './CreateAccountForm';

interface CreateAccountModalProps {
  AuthStore: IAuthStore;
  UiStore: IUiStore;
}

const CreateAccountModalImpl: React.FC<CreateAccountModalProps> = ({
  AuthStore,
  UiStore,
}) => {
  const [existingUserDetails, setExistingUserDetails] = React.useState<
    Pick<
      UserRead,
      | 'firstName'
      | 'lastName'
      | 'displayFirstName'
      | 'displayLastName'
      | 'email'
      | 'smsAgreement'
      | 'smsOptOutTypes'
      | 'phone'
      | 'inviteChannel'
    >
  >({});
  const router = useRouter();

  React.useEffect(() => {
    trackEvent({
      name: 'Create Account Started',
      properties: {},
    });
  }, []);

  const registerUser = async (
    {
      firstName,
      lastName,
      displayFirstName,
      displayLastName,
      hasDisplayNameChecked,
      displayNameCheckedCount,
      email,
      password,
      smsAgreementViewed,
      smsOptIn,
      phone,
    }: CreateAccountFormValues,
    formikHelpers: FormikHelpers<CreateAccountFormValues>
  ) => {
    try {
      trackEvent({
        name: 'Create Account Submitted',
        properties: {},
      });
      formikHelpers.setStatus('');
      let user;
      if (AuthStore.inviteToken) {
        const completeAccountValues: CompleteAccountRequest = {
          password,
          firstName,
          lastName,
          phone,
        };
        if (hasDisplayNameChecked) {
          completeAccountValues.displayFirstName = displayFirstName;
          completeAccountValues.displayLastName = displayLastName;
        }
        if (smsAgreementViewed) {
          const nowIsoString = moment().toISOString();
          completeAccountValues.smsAgreement = nowIsoString;
          completeAccountValues.smsOptOutTypes = smsOptIn
            ? []
            : [UserSmsOptOutType.ALL];
          completeAccountValues.smsPhone = phone;
        }
        await AuthApi.completeAccount(completeAccountValues, {
          headers: {
            Authorization: `Bearer ${AuthStore.inviteToken}`,
          },
        });
        user = await UserApi.getUserMe();
        AuthStore.setUser(user);
      } else {
        const signupValues: SignupRequest = {
          firstName,
          lastName,
          email,
          password,
          redirectTo: router.asPath,
        };
        if (hasDisplayNameChecked) {
          signupValues.displayFirstName = displayFirstName;
          signupValues.displayLastName = displayLastName;
        }
        user = await AuthStore.signUp(signupValues);
        AuthStore.setUser(user);
      }

      const nameMatch =
        (displayFirstName === '' && displayLastName === '') ||
        (displayFirstName === firstName && displayLastName === lastName);
      trackEvent({
        name: 'Create Account Completed',
        properties: {
          differentNameCheckboxSelectionCount: displayNameCheckedCount,
          nameCheckbox: hasDisplayNameChecked,
          nameMatch: [nameMatch],
        },
      });

      if (user?.email) {
        if (!user.isVerified) {
          UiStore.showSuccessAlert(
            "You're almost there",
            'Your privacy is very important to us so we just sent you an email to confirm your identity. Please check it to verify your account.'
          );
        } else if (
          router.query.referrer &&
          typeof router.query.referrer === 'string' &&
          router.query.referrer.startsWith('/') // Only allow paths to prevent XSS.
        ) {
          await router.push(router.query.referrer);
        } else {
          window.location.assign(router.asPath);
        }
      }
      AuthStore.inviteToken = undefined;
      AuthStore.closeSignupModal();
      formikHelpers.resetForm();
    } catch (err: unknown) {
      // we don't need to log an error if it's bc of a duplicate signup or because the request was unauthenticated
      if (!isDuplicateRequestError(err) && !isUnauthenticatedError(err)) {
        logException(err);
      }

      formikHelpers.setStatus(
        "We can't create an account with this email. Double-check your information and try again."
      );
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  React.useEffect(() => {
    trackPageView({ name: 'Signup Modal' });

    if (AuthStore.inviteToken) {
      const token = AuthStore.inviteToken!;

      const verifyInvite = async () => {
        try {
          await AuthApi.verifyInvite({ token }).then((res) => {
            const {
              firstName,
              lastName,
              displayFirstName,
              displayLastName,
              email,
              smsAgreement,
              smsOptOutTypes,
              phone,
              inviteChannel,
            } = res;
            setExistingUserDetails({
              firstName,
              lastName,
              displayFirstName,
              displayLastName,
              email,
              smsAgreement,
              smsOptOutTypes,
              phone,
              inviteChannel,
            });
          });
        } catch (err) {
          notifyError(
            'This invite link is expired, used up, or otherwise invalid.'
          );
          if (!isUnauthenticatedError(err)) {
            logException(err);
          }
        }
      };
      if (!AuthStore.user.isVerified) {
        verifyInvite();
      }
    } else {
      UserApi.getUserMe().then((user) => {
        setExistingUserDetails({
          firstName: user.firstName,
          lastName: user.lastName,
          displayFirstName: user.displayFirstName,
          displayLastName: user.displayLastName,
          email: user.email || user.questionnaireEmail,
          smsAgreement: user.smsAgreement,
          smsOptOutTypes: user.smsOptOutTypes,
          phone: user.phone,
        });
      });
    }
  }, [AuthStore.inviteToken, AuthStore.user.isVerified]);

  const onLoginButtonClick = () => {
    AuthStore.closeSignupModal();
    AuthStore.openLoginModal();
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={() => AuthStore.closeSignupModal()}
      title={`${
        AuthStore.user && AuthStore.user.email ? 'Complete' : 'Create'
      } Your Headway Account`}
    >
      <CreateAccountForm
        existingUserDetails={existingUserDetails}
        registerUser={registerUser}
        onLoginButtonClick={onLoginButtonClick}
      />
    </Modal>
  );
};

export const CreateAccountModal = withStores(CreateAccountModalImpl);
