import * as React from 'react';

export const YearsOfExperience = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.085 18.674c.098.098.196.098.294.098.099 0 .197-.049.295-.098a.386.386 0 000-.54l-6.287-6.288V3.053a.423.423 0 00-.393-.393.423.423 0 00-.393.393v8.94c0 .099.049.197.098.295l6.386 6.386zM14.45 9.881c-.098 0-.197-.05-.295-.098a.385.385 0 010-.54l3.832-3.832a.386.386 0 01.54 0 .385.385 0 010 .54l-3.832 3.832c0 .098-.098.098-.245.098zm1.08 2.505h5.404a.423.423 0 00.393-.393.423.423 0 00-.393-.393H15.53a.423.423 0 00-.393.393c0 .197.197.393.393.393zM9.488 9.881c-.098 0-.196-.05-.294-.098L5.362 5.95a.385.385 0 010-.54.385.385 0 01.54 0l3.832 3.831a.385.385 0 010 .54c-.05.1-.147.1-.246.1zm-6.435 2.505h5.404a.387.387 0 00.393-.393.423.423 0 00-.393-.393H3.053a.423.423 0 00-.393.393c0 .197.197.393.393.393zm2.604 6.337c-.099 0-.197-.049-.295-.098a.385.385 0 010-.54l3.832-3.832a.385.385 0 01.54 0 .385.385 0 010 .54l-3.832 3.832a.375.375 0 01-.245.098zm5.944 2.21c0 .197.196.394.393.394a.423.423 0 00.393-.393V15.53a.423.423 0 00-.393-.393.423.423 0 00-.393.393v5.404z"
      fill="#212326"
    />
  </svg>
);
