import * as React from 'react';

export const LicenseTypes = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.16 6.99v7.65c0 .22-.18.41-.41.41-.23 0-.41-.18-.41-.41V7.26H7.07c-.22 0-.41-.18-.41-.41 0-.23.18-.41.41-.41h11.54c.3 0 .54.24.54.54l.01.01zm-2.17 2.17v7.51c0 .3-.24.54-.54.54H5.04c-.3 0-.54-.24-.54-.54V9.16c0-.3.24-.54.54-.54h11.41c.3 0 .54.24.54.54zm-.81.27H5.31v6.97h10.86V9.43h.01z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
