import * as React from 'react';

export const RareFind = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.284 8.815l-1.758-2.768c-.278-.436-.79-.707-1.334-.707H7.808c-.545 0-1.056.27-1.334.707L4.716 8.815a1.359 1.359 0 000 1.47l5.398 7.625c.283.44.717.753 1.223.9h.004a2.39 2.39 0 001.32.001h.003c.506-.148.942-.46 1.223-.9l5.386-7.61.011-.016a1.36 1.36 0 000-1.47zm-6.274-2.72l1.953 3.077H9.038l1.953-3.077h2.019zm-5.897.357c.14-.221.407-.357.695-.357h2.287L8.14 9.172H5.386l1.727-2.72zM5.39 9.928h2.69l2.612 7.49-5.302-7.49zm6.87 8.195a1.612 1.612 0 01-.26.023c-.09 0-.176-.01-.262-.023L8.88 9.928h6.238l-2.858 8.195zm1.048-.706l2.613-7.49h2.69l-5.303 7.49zm2.55-8.245l-1.953-3.077h2.287a.82.82 0 01.695.357l1.727 2.72h-2.755z"
      fill="#212326"
    />
  </svg>
);
